import React from 'react'

import ArrowIcon from '@/assets/images/arrow.svg'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { media } from '@/styles/media'
import { Icon } from './Icon'

const Button = styled(Link)<{ bgColor: string; color: string }>`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};

  border-radius: 360px;

  display: inline-flex;
  max-width: fit-content;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 20px;

  text-decoration: none;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  box-shadow: none;

  ${media.tabletLandscapeDown} {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    height: 56px;

    padding: 12px 18px;
  }
`

const ButtonHref = styled.a<{ bgColor: string; color: string }>`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};

  border-radius: 360px;

  display: inline-flex;
  max-width: fit-content;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 20px;

  text-decoration: none;

  ${media.tabletLandscapeDown} {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    height: 56px;

    padding: 12px 18px;
  }
`

export default function CtaButton({
  text,
  bgColor,
  color,
  to,
  icon = ArrowIcon,
  style = {},
  target = ''
}) {
  return target ? (
    <ButtonHref
      href={to}
      target={target}
      bgColor={bgColor}
      color={color}
      style={style}
    >
      {text} <Icon src={icon} />
    </ButtonHref>
  ) : (
    <Button to={to} bgColor={bgColor} color={color} style={style}>
      {text} <Icon src={icon} />
    </Button>
  )
}
