import React from 'react'
import { useTranslation } from 'react-i18next'

import Layout from '@/components/layouts/Layout'
import styled from '@emotion/styled'
import error404Icon from '@/assets/images/error_404.svg'
import { media } from '@/styles/media'
import CtaButton from '../components/generic/CtaButton'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  height: 800px;
  margin: 0 auto;
  ${media.tabletLandscapeDown} {
    width: 100%;
    height: 100vh;
  }
`

const Frame = styled.div`
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  flex: 1 0 0;
  align-self: stretch;
`

const ImgWrapper = styled.div`
  display: flex;
  width: 176px;
  height: 120px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

const ImgIcon = styled.img`
  width: 96px;
  height: 70px;
  flex-shrink: 0;
`

const MsgFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 36px;
  align-self: stretch;
  white-space: nowrap;
  word-break: keep-all;
  ${media.tabletLandscapeDown} {
    white-space: normal;
  }
`

const TitleHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  align-self: stretch;
  width: 100%;
  height: auto;
`

const Title = styled.div`
  color: #002e4e;
  leading-trim: both;
  text-edge: cap;
  font-size: 56px;
  font-style: normal;
  font-weight: 800;
  line-height: 56px;
  letter-spacing: -1.76px;
  padding: 0;
  margin: 0;
  ${media.tabletLandscapeDown} {
    font-size: 44px;
  }
`

const CtaWrapper = styled.div`
  display: flex;
  height: 56px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

const Description = styled.p`
  align-self: stretch;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-style: normal;
  font-size: 24px;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;
  margin: 0;
  word-break: keep-all;
  ${media.tabletLandscapeDown} {
    white-space: normal;
    font-size: 16px;
  }
`

export default function NotFound() {
  const { t } = useTranslation()

  return (
    <Layout
      isFooterDisabled
      pageTitle={t('error.error_404_title')}
      pageCanonical="https://ichigo.com/404"
      LayoutContainer={Wrapper}
    >
      <Frame>
        <ImgWrapper>
          <ImgIcon src={error404Icon} />
        </ImgWrapper>
        <MsgFrame>
          <TitleHeader>
            <Title>{t('error.error_404_title')}</Title>
          </TitleHeader>
          <Description>{t('error.error_404_description')}</Description>
        </MsgFrame>
        <CtaWrapper>
          <CtaButton
            to="/"
            text={t('error.error_404_go_to_home')}
            color="#064A79"
            style={{
              color: 'white',
              borderRadius: '50px',
              textAlign: 'center',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHight: '24px'
            }}
            bgColor="#123456"
          />
        </CtaWrapper>
      </Frame>
    </Layout>
  )
}
